//==================================================
// Polyfill for pseudo selector :placeholder-shown
// Author: Subash Selvaraj(github userid:sesubash)
// Modifications by Charlie O'Leary (ATechnology)
//==================================================

interface Window {
  PlaceholderShownPolyfill: any;
  NeedsPlaceholderShown: any;
}

(() => {

  const placeholderShownPolyfill = {
    init() {
      const self = this;
      if (typeof window.NeedsPlaceholderShown === 'undefined') {
        window.NeedsPlaceholderShown = false;
        // Check for support of this psuedo selector
        let psuedoSupported = false;

        const testInput = document.createElement('input');
        testInput.id = 'testinput';
        testInput.setAttribute('placeholder', 'test');
        try {
          psuedoSupported = testInput.matches(':placeholder-shown');
        } catch (e) {
          // Not supported, leave as false
        }

        window.NeedsPlaceholderShown = !psuedoSupported;
      }

      if (window.NeedsPlaceholderShown) {
        document.body.classList.add('not-placeholder-shown');
        placeholderShownPolyfill.updateAll();
      }
    },

    updateAll() {
      const self = this;
      const placeholderEls = document.querySelectorAll('[placeholder]');
      if (placeholderEls && window.NeedsPlaceholderShown) {
        document.querySelectorAll('[placeholder]').forEach(el => {
          if (el.getAttribute('placeholder') != "")
            self.updatePlaceholder.call(el);

          el.addEventListener('change', self.updatePlaceholder);
          el.addEventListener('keyup', self.updatePlaceholder);
        });
      }
    },

    updatePlaceholder() {
      this.classList[this.value ? 'remove' : 'add']('placeholder-shown');
    }

  };

  if (/^[c|i]|d$/.test(document.readyState || "")) {
    placeholderShownPolyfill.init();
  } else {
    document.addEventListener('DOMContentLoaded', placeholderShownPolyfill.init);
  }

  window.PlaceholderShownPolyfill = placeholderShownPolyfill;

})();
