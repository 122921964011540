"use strict";

interface Element {
  msMatchesSelector: any;
}

(() => {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }
})();
